<template>
  <div class="signin-page notranslate">
    <banner :link="link" />

    <main class="container">
      <div v-if="advisor" class="advisor-greeting">
        <img v-if="advisor.photoUrl" :class="{'advisor-photo':!isImageLogo,'advisor-logo':isImageLogo}" :alt="photoAlt" :src="advisor.photoUrl" />
        <span class="advisor-photo initial">
          {{ advisor.firstName.charAt(0) + advisor.lastName.charAt(0) }}
        </span>
        <span class="small">
          {{ signinIntro }}
        </span>
      </div>
      <div v-if="clientEmail" class="signin-content">
        <div class="form-content">
          <hybrid-signin-form
            :post-auth-and-get-redirect-url="getRepresentativeLink"
            :has-advisor="true"
          />

          <p class="center">
            <span v-t>Don't have an account yet? </span>
            <a v-t :href="link.url">Sign up</a>
          </p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Banner from '@layoutTheme/banner';
import HybridSigninForm from '~vue/components/forms/form-hybrid-signin';
import { http } from '~vue/helpers/http';
import { capitalize } from '~vue/helpers/filters';
import { BRAND, BRAND_FULL_NAME } from '~vue/config/brand';

export const deps = { http };

export default {
  components: {
    Banner,
    HybridSigninForm,
  },
  filters: { capitalize },
  data() {
    return {
      link: {
        title: 'Sign Up',
        url: 'representative-invitation',
      },
      clientEmail: null,
      advisor: null,
      token: null,
      brandFullName: BRAND_FULL_NAME,
      brand: BRAND,
    };
  },
  computed: {
    signinIntro() {
      return this.$t('You are now considering opening an account with %1.', [this.brandFullName]);
    },
    photoAlt() {
      return this.$t('photo for %1 %2', [this.advisor.firstName, this.advisor.lastName]);
    },
    isImageLogo() { return this.advisor.photoUrl ? this.advisor.photoUrl.includes('advisor_logo') : false; },
  },
  created() {
    this.token = (this.$route.query.token || this.$route.params.token);

    if (this.token) {
      this.link.url = `../representative-invitation?token=${this.token}`;
      const endSessionPromise = deps.http.delete('/api/sessions');
      const fetchInvitePromise = deps.http.get(`/advisor_api/client_invitations/${this.token}`);

      Promise.all([endSessionPromise, fetchInvitePromise]).then(response => {
        const fetchInviteResponse = response[1];
        const client = fetchInviteResponse.data.client_invitations_data;
        const advisor = fetchInviteResponse.data.advisor_invitation_data;
        const accepted = client.accepted_at;
        const declined = client.declined_at;
        const { archived } = client;

        if (accepted != null) {
          this.$router.push({ path: '/signin' });
        } else if (archived === true || declined != null) {
          this.$router.push({ path: '/representative-invitation/invalid' });
        } else {
          this.clientEmail = client.email;
          this.advisor = {
            firstName: advisor.first_name,
            lastName: advisor.last_name,
            photoUrl: advisor.photo_url,
          };
        }
      }).catch(() => {
        this.$router.push({ path: '/representative-invitation/invalid' });
      });
    } else {
      this.$router.push({ path: '/signin' });
    }
  },
  methods: {
    async getRepresentativeLink() {
      try {
        const response = await deps.http.put('/advisor_api/link_invitation', { token: this.token });
        const returnToken = response.data.invitation_token;

        return `/app/representative-link?token=${returnToken}`;
      } catch (error) {
        return '/representative-invitation/invalid';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .signin-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  .container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
  .advisor-greeting {
    padding: 2rem 0;
    border-bottom: 1px solid $neutral-100;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    @media #{$screen-width-medium} {
      border: none;
      margin: 0;
    }
  }
  .advisor-logo, .advisor-photo {
    flex: 0 0 auto;
    display: inline-block;
    margin-right: 1rem;
    vertical-align: middle;
    &.initial {
      background: $primary-300;
      color: $white;
      text-align: center;
      vertical-align: baseline;
      text-transform: uppercase;
      font-size: 3rem;
      line-height: 6rem;
    }
  }
  .advisor-logo {
    width: auto;
    height: auto;
    max-height: 7rem;
    max-width: 10rem;
    @media #{$screen-width-medium} {
      max-height: 10rem;
      max-width: 15rem
    }
  }
  .advisor-photo {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
  }

  .signin-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-items: center;
  }

  .title {
    display: flex;
    align-items: baseline;
  }

  .signin-content {
    display: flex;
    width: 100%;
    justify-content: center;

    @media #{$screen-width-medium} {
      width: 39.75rem;
      border-top: 1px solid $neutral-100;
    }
  }

  .center {
    text-align: center;
  }

  .form-content {
    flex: 0 1 20rem;
    @media #{$screen-width-medium} {
      min-width: 20rem;
    }
  }
</style>
