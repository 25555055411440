import storage from 'utils/storage';
import { http } from '~vue/helpers/http';
import { getLocale } from '~vue/helpers/localization';

export function setLoginData(user, store = storage, win = window) {
  store.setItem('user', JSON.stringify({
    id: user.id,
    email: user.email,
    language: user.language,
    hasAdvisor: user.has_external_advisor,
    isGroupPlanUser: user.is_group_plan_user
  }));
  if (win.analytics && win.analytics.alias && win.analytics.track) {
    win.analytics.alias(user.id);
    win.analytics.track('Signin', {
      category: 'User',
      label: 'Success',
    });
  }
}

export function ssoLogin(win = window) {
  win.location = 'sso/initiate';
}

export function reEnableSigninButton(win = window) {
  const submitBtn = win.document.getElementById('okta-signin-submit');
  submitBtn?.removeAttribute('disabled');
  submitBtn?.classList.remove('link-button-disabled');
}

export async function apiLogin(creds, errorMessage, postAuthAndGetRedirectUrl, httpPost = http.post, win = window, doReEnableSigninButton = reEnableSigninButton) {
  try {
    const { data } = await httpPost('/api/sessions', {
      email: creds.username,
      password: creds.password,
    });
    setLoginData(data);
    const postAuthRedirectUrl = await postAuthAndGetRedirectUrl();
    const isRepresentativeLink = postAuthRedirectUrl.includes('representative-link') || postAuthRedirectUrl.includes('representative-invitation');

    if (!isRepresentativeLink && data.prompt_two_factor_signup) {
      win.location = '/app/two-factor-signup';
      return;
    } else {
      win.location = postAuthRedirectUrl;
      return;
    }
  } catch (error) {
    /* istanbul ignore next */
    if (!error.response.data) throw error;
    if (error.response.status === 499) {
      win.location = `/two-factor?method=${error.response.data.tfaMethod}`;
      return;
    }
    errorMessage(error.response.data && error.response.data.message);
    doReEnableSigninButton();
  }
}

export async function handleCreds(creds, callback, errorMessage, postAuthAndGetRedirectUrl, hasAdvisor = false, httpPost = http.post, tryApiLogin = apiLogin, doSsoLogin = ssoLogin, doReEnableSigninButton = reEnableSigninButton, doGetLocale = getLocale, win = window) {
  errorMessage(false);
  const locale = await doGetLocale();
  const env = win?.process?.env;

  if (creds.username.match(/@wealthbar\.com$/)) {
    doSsoLogin();
  } else {
    try {
      await httpPost('/dashboard-api/process-creds', {
        email: creds.username,
        locale: locale
      });
      callback();
      return;
    } catch (e) {
      if (e.response.status === 401) {
        errorMessage(e.response.data && e.response.data.message)
        doReEnableSigninButton();
        return
      }
      if(env.LEGACY_SIGNIN || hasAdvisor) {
        tryApiLogin(creds, errorMessage, postAuthAndGetRedirectUrl);
      } else {
        const dashboardUrl = locale && locale.slice(0, 2) === 'fr' ? env.COMMON_DASHBOARD_SIGNIN_FR : env.COMMON_DASHBOARD_SIGNIN_EN;
        win.location = dashboardUrl;
      }
    }
  }
}
